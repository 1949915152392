<script>
  export let width = "100%"
  export let height = "100%"
</script>

<svg {width} {height} viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg"
     xml:space="preserve"
     style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(1,0,0,1,-103.85,-130)">
        <g transform="matrix(1.05561,0,0,0.654266,5.88834,6.25641)">
            <g transform="matrix(0.947316,0,0,1.52843,92.5653,188.527)">
                <path d="M144.106,15.569C144.106,7.19 137.313,0.397 128.933,0.397L15.421,0.397C7.042,0.397 0.249,7.19 0.249,15.569L0.249,129.082C0.249,137.461 7.042,144.254 15.421,144.254L128.933,144.254C137.313,144.254 144.106,137.461 144.106,129.082L144.106,15.569Z" style="fill:rgb(173,148,148);"/>
            </g>
            <g transform="matrix(0.947316,0,0,1.52843,92.5653,460.697)">
                <path d="M144.106,15.569C144.106,7.19 137.313,0.397 128.933,0.397L15.421,0.397C7.042,0.397 0.249,7.19 0.249,15.569L0.249,129.082C0.249,137.461 7.042,144.254 15.421,144.254L128.933,144.254C137.313,144.254 144.106,137.461 144.106,129.082L144.106,15.569Z" style="fill:rgb(146,98,106);"/>
            </g>
            <g transform="matrix(0.947316,0,0,1.52843,92.5653,732.867)">
                <path d="M144.106,15.569C144.106,7.19 137.313,0.397 128.933,0.397L15.421,0.397C7.042,0.397 0.249,7.19 0.249,15.569L0.249,129.082C0.249,137.461 7.042,144.254 15.421,144.254L128.933,144.254C137.313,144.254 144.106,137.461 144.106,129.082L144.106,15.569Z" style="fill:rgb(104,122,157);"/>
            </g>
            <g transform="matrix(0.947316,0,0,1.52843,261.02,188.527)">
                <path d="M144.106,15.569C144.106,7.19 137.313,0.397 128.933,0.397L15.421,0.397C7.042,0.397 0.249,7.19 0.249,15.569L0.249,129.082C0.249,137.461 7.042,144.254 15.421,144.254L128.933,144.254C137.313,144.254 144.106,137.461 144.106,129.082L144.106,15.569Z" style="fill:rgb(93,141,172);"/>
            </g>
            <g transform="matrix(0.947316,0,0,1.52843,261.02,460.697)">
                <path d="M144.106,15.569C144.106,7.19 137.313,0.397 128.933,0.397L15.421,0.397C7.042,0.397 0.249,7.19 0.249,15.569L0.249,129.082C0.249,137.461 7.042,144.254 15.421,144.254L128.933,144.254C137.313,144.254 144.106,137.461 144.106,129.082L144.106,15.569Z" style="fill:rgb(146,154,131);"/>
            </g>
            <g transform="matrix(0.947316,0,0,1.52843,261.02,732.867)">
                <path d="M144.106,15.569C144.106,7.19 137.313,0.397 128.933,0.397L15.421,0.397C7.042,0.397 0.249,7.19 0.249,15.569L0.249,129.082C0.249,137.461 7.042,144.254 15.421,144.254L128.933,144.254C137.313,144.254 144.106,137.461 144.106,129.082L144.106,15.569Z" style="fill:rgb(130,106,105);"/>
            </g>
            <g transform="matrix(0.947316,0,0,1.52843,429.71,188.527)">
                <path d="M144.106,15.569C144.106,7.19 137.313,0.397 128.933,0.397L15.421,0.397C7.042,0.397 0.249,7.19 0.249,15.569L0.249,129.082C0.249,137.461 7.042,144.254 15.421,144.254L128.933,144.254C137.313,144.254 144.106,137.461 144.106,129.082L144.106,15.569Z" style="fill:rgb(202,139,22);"/>
            </g>
            <g transform="matrix(0.947316,0,0,1.52843,429.71,460.697)">
                <path d="M144.106,15.569C144.106,7.19 137.313,0.397 128.933,0.397L15.421,0.397C7.042,0.397 0.249,7.19 0.249,15.569L0.249,129.082C0.249,137.461 7.042,144.254 15.421,144.254L128.933,144.254C137.313,144.254 144.106,137.461 144.106,129.082L144.106,15.569Z" style="fill:rgb(141,128,155);"/>
            </g>
            <g transform="matrix(0.947316,0,0,1.52843,429.71,732.867)">
                <path d="M144.106,15.569C144.106,7.19 137.313,0.397 128.933,0.397L15.421,0.397C7.042,0.397 0.249,7.19 0.249,15.569L0.249,129.082C0.249,137.461 7.042,144.254 15.421,144.254L128.933,144.254C137.313,144.254 144.106,137.461 144.106,129.082L144.106,15.569Z" style="fill:rgb(169,163,156);"/>
            </g>
        </g>
    </g>
</svg>

<style>

</style>